jQuery(document).ready(function($){
    console.log('ありがとうございます。世界中のひとが皆幸せを感じられる事をここに祈ります。');
    // 全画面共通
    // スマホ、メニュー制御
    $('[data-navi_menu]').on('click',function(){
        const navi_menu = $(this).data('navi_menu');
        if(navi_menu === 0){
            if($(this).hasClass('first')){
                $(this).removeClass('first');
            }
            $('.menu').addClass('active').removeClass('close');
            $(this).removeClass('active').addClass('close');
            $('[data-navi_menu=1]').addClass('active').removeClass('close');
            $('body').addClass('cant_scroll');
        }else{
            $('.menu').removeClass('active').addClass('close');
            $(this).removeClass('active').addClass('close');
            $('[data-navi_menu=0]').addClass('active').removeClass('close');
            $('body').removeClass('cant_scroll');
        }
    });

    const slidein_elements = document.querySelectorAll('[class*="slidein"]'); //アニメーションさせたい要素
    function elementEnterScreen(slidein_elements){
        const scroll = window.scrollY; //スクロール量を取得
        const windowHeight = window.innerHeight; //画面の高さを取得
        slidein_elements.forEach(function(element, index, array){
            const elementPos = element.getBoundingClientRect().top + scroll; //ターゲット要素の位置を取得
            const elementHeight = element.clientHeight; // ターゲットの高さ
            if (scroll > elementPos + element.clientHeight - windowHeight) { //スクロール量 > ターゲット要素の位置
                element.classList.add('active'); //activeクラスを加える
            }
        });
    }
    //画面描画時イベント
    elementEnterScreen(slidein_elements);
    //スクロールイベント
    window.addEventListener('scroll', function () {
        elementEnterScreen(slidein_elements);
    });

    /**
     * 全画面共通
     * パララックスを動作させる。
     * 動作させたいimgタグに　data-parallax="方向,倍率"　を記載
     */
    const parallax_elements = document.querySelectorAll('[data-parallax]');
    if(parallax_elements.length){            
        parallax_elements.forEach(function(element, index, array){
            const option_array = element.dataset.parallax.split(',');
            new simpleParallax(element,{
                orientation: option_array[0],
                scale: option_array[1]
            });
        });
    }
    // let before_window = 'under_pcs';
    // $(window).resize(function () {
    //     const width = $(this).width();
    //     let now_window = '';
    //     if(width <= 1365){
    //         now_window = 'under_pcs';
    //     }else{
    //         now_window = 'over_pcs';
    //     }
    //     if(before_window != now_window){
    //         if(parallax_elements.length){            
    //             parallax_elements.forEach(function(element, index, array){
    //                 const option_array = element.dataset.parallax.split(',');
    //                 if(option_array[2] == 'responsible'){
    //                     new simpleParallax(element,{
    //                         orientation: option_array[0],
    //                         scale: option_array[3]
    //                     });
    //                 }
    //             });
    //         }
    //     }
    // });

    /**
     * 稽古日程カレンダーを動かす
     */
    const calendarEl = document.getElementById('regular_schedule');
    if(calendarEl){
        const calendar = new FullCalendar.Calendar(calendarEl, {
            locale: 'ja',
            googleCalendarApiKey: 'AIzaSyBcbkCEdKtqT5RvURP2vy8y8GXM4YZUgBE',
            events: 
            {
                googleCalendarId: 'sawadadojo.karate@gmail.com'
            }
        });
        calendar.render();
    }

    // TOP画面
    if($('#top').length){
        // トップ画像のスリック
        var $top_body_slider = $('.top__body--slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            speed: 2000,
            autoplaySpeed: 8000,
            autoplay: true
        })
        .on({
            beforeChange: function(event, slick, currentSlide, nextSlide) {
                $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
                $(".slick-slide", this).eq(nextSlide).addClass("slide-animation");
            },
            afterChange: function() {
                $(".preve-slide", this).removeClass("preve-slide slide-animation");
            }
        });
        $top_body_slider.find(".slick-slide").eq(0).addClass("slide-animation");
        // faqのアコーディオン
        $('.faq__body--qanda').on('click', function(){
            this_element = $(this);
            this_element.find('.answer').slideToggle(300, function(){
                this_element.find('.a_arrow').toggleClass('active');
            });
        });


    }
    // About画面
    if($('#about').length){
        // 稽古風景画像のスリック
        $('.practice__body--detail').each(function(i){
            let slick_option = {};
            if($(this).attr('dir') === 'rtl'){
                slick_option = {
                    autoplay: true, // 自動でスクロール
                    autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
                    speed: 5000, // スライドが流れる速度を設定
                    cssEase: "linear", // スライドの流れ方を等速に設定
                    slidesToShow: 4, // 表示するスライドの数
                    swipe: false, // 操作による切り替えはさせない
                    arrows: false, // 矢印非表示
                    pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
                    pauseOnHover: true, // スライダーにマウスホバーした時にスライドを停止させるか
                    rtl: true,
                }
            }else{
                slick_option = {
                    autoplay: true, // 自動でスクロール
                    autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
                    speed: 5000, // スライドが流れる速度を設定
                    cssEase: "linear", // スライドの流れ方を等速に設定
                    slidesToShow: 4, // 表示するスライドの数
                    swipe: false, // 操作による切り替えはさせない
                    arrows: false, // 矢印非表示
                    pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
                    pauseOnHover: true, // スライダーにマウスホバーした時にスライドを停止させるか
                }
            }
            $(this).slick(slick_option);
        });
        // historyのアコーディオン
        $('.history__body--detail').on('click', function(){
            this_element = $(this);
            this_element.find('.answer').slideToggle(300, function(){
                this_element.find('.a_arrow').toggleClass('active');
            });
        });
    }

    // About画面
    if($('#started').length){
        $('#form_input input:text,#form_input [type=number],#form_input [type=email],#form_input [type=tel],#form_input [type=date],#form_input textarea').on('change',function(){
            const change_name = $(this).attr('name');
            const change_val = $(this).val();

            $('#form_check [name^='+change_name+']').val(change_val);
        });
        $('#form_input input:checkbox').on('change',function(){
            const change_name = $(this).attr('name');
            const change_val = $(this).prop('checked');
            $('#form_check [name^='+change_name+']').prop('checked', change_val);
        });
        $( '#form_input' ).submit(function(event){
            $('[type="submit"]').prop('disabled', true);
            const ajaxurl = $('.urls').data('ajaxurl');
            // フォームデータから、サーバへ送信するデータを作成
            const fd = new FormData( this );
            // サーバー側で何の処理をするかを指定。後ほどphp側で実装する
            fd.append('action'  , 'contactform_validation' );
       
            // ajaxの通信
            $.ajax({
                type: 'POST',
                url: ajaxurl,
                data: fd,
                processData: false,
                contentType: false,
                success: function( response ){
                    if(response.length == 0){
                        $('#form_input').hide();
                        $('#form_check').fadeIn();
                        $('#form_input').append('<input id="validated" type="hidden" name="validated" value="OK牧場">')
                        $('[type="submit"]').prop('disabled', false);
                    }
                },
                error: function( response ){
                    alert('通信状態を確認して、再度確認ボタンを押してください。');
                }
            });
            return false;
        });

        // formの送信ボタンが押されたときの処理
        $(document).on('submit', '#form_check',function(event){
            // クリックイベントをこれ以上伝播させない
            $('[type="submit"]').prop('disabled', true);
            let form_check = document.getElementById('form_input');
            // フォームデータから、サーバへ送信するデータを作成
            const fd = new FormData( form_check );
            // サーバー側で何の処理をするかを指定。後ほどphp側で実装する
            fd.append('action'  , 'contactform' );
            const ajaxurl = $('.urls').data('ajaxurl');
            const completeurl = $('.urls').data('completeurl');
            // ajaxの通信
            $.ajax({
                type: 'POST',
                url: ajaxurl,
                data: fd,
                processData: false,
                contentType: false,
                success: function( response ){
                    if(response == 'true'){
                        window.location.href = completeurl;
                    }
                    $('[type="submit"]').prop('disabled', false);
                },
                error: function( response ){
                    $('[type="submit"]').prop('disabled', false);
                    alert('問合せ内容送信に失敗しました。通信環境を確認の上もう一度お問い合わせください。');
                }
            });
            return false;
        });
        // formの修正ボタンが押されたときの処理
        $( '#form_check .reset [type="submit"]' ).on('click', function(event){
            // クリックイベントをこれ以上伝播させない
            $('#form_check').hide(); 
            $('#form_input').fadeIn();
            $('#form_input #validated').remove();
            return false;
        });
    }

    // Reservation画面
    if($('#reservation').length){
        
        $('.add_reservation').on('click',function(){
            const clone_element = $('.clone').clone().removeClass('clone');
            const clone_element_length = $('.reservation_detail').length;
            clone_element.find('[name^=date_clone]').prop('disabled', false);
            clone_element.find('[name^=detail_clone]').prop('disabled', false);
            clone_element.find('[name^=date_clone]').attr('name', 'date[]').prop('disabled', false).attr('required',true);
            clone_element.find('[name^=detail_clone]').attr('name', 'detail[]').prop('disabled', false);
            $('.add_reservation').closest('dl').before(clone_element);
            btn_controll();
            return false;
        });

        $('.remove_reservation').on('click',function(){
            const detali_elements = $('.reservation_detail');
            if(detali_elements.length > 1){
                detali_elements[detali_elements.length - 1].remove();
                btn_controll();
            }
            return false;
        });
        function btn_controll(){
            const clone_element_length = $('.reservation_detail').length;
            if(clone_element_length == 1){
                $('.remove_reservation').css('opacity','0');
            }else{
                $('.remove_reservation').css('opacity','1');
            }
        }
    }

});